import { autoinject, computedFrom, observable, bindable } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { Empresa } from 'entities/empresa';
import * as _ from 'lodash';
import { EyBasePage } from 'components/ey-base-page';
import { Api } from 'utils/api';
import { Documento } from 'entities/documento';
import { Trabajador } from 'entities/trabajador';
import { EyDocumentos, FilterType } from 'components/ey-documentos';
import { EmpresaCdT } from 'entities/centro';
import AuthService from 'authService';
import { EyTrabajadores } from 'components/ey-trabajadores';
import { EyMaquinas } from 'components/ey-maquinas';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

@autoinject()
export class EmpresaEdit extends EyBasePage {
  @observable empresa: Empresa;
  @observable documentos: Documento[] = [];
  // @observable centros: EmpresaCdT[] = [];
  @observable trabajadores: Trabajador[] = [];

  @bindable validadoSeleccion: string = "0";
  @observable terminado: boolean = false;

  // documentTable: any;
  tab: string = "empresa"
  tabCdt: string = "alta"
  table: any;

  docWidget: EyDocumentos;
  trabWidget: EyTrabajadores;
  maqWidget: EyMaquinas;
  filterType: FilterType = FilterType.POR_SUBIR;

  trabSub: Subscription;
  maqSub: Subscription;
  docSub: Subscription;

  constructor(private api: Api, public router: Router, private ea: EventAggregator,
    private auth: AuthService) {
    super(router);

    if (!auth.authData.tabDocEmpresa) {
      this.tab = "centros";
    }
  }

  activate(params) {
    console.log("EmpresaEdit activated");
    this.getEmpresa(params.empID);
    if (params.filterType) {
      this.filterType = Number(params.filterType);
      console.log("Filter by", this.filterType, typeof params.type);
    }

    this.trabSub = this.ea.subscribe("trabajador::edit", (data) => {
      this.router.navigateToRoute('trabajadorEdit', { empID: data.empID, trabID: data.trabID, filterType: data.filterType });
    });

    this.docSub = this.ea.subscribe("select::doc", (data) => {
      console.log("select::doc", data);
      if (data.owner instanceof EmpresaCdT) {
        this.editCentro(data.owner, data.filterType);
      }
    });

    this.maqSub = this.ea.subscribe("maquina::edit", (data) => {
      this.router.navigateToRoute('maquinaEdit', { empID: data.empID, maqID: data.maqID, filterType: data.filterType });
    });
  }

  deactivate() {
    console.log("EmpresaEdit deactivated");
    this.trabSub.dispose();
    this.maqSub.dispose();
    this.docSub.dispose();
  }

  attached() {
    console.log("EmpresaEdit attached");

    if (this.auth.authData.tabDocEmpresa) {
      this.docWidget.parent = this;
      this.docWidget.setFilter(this.filterType);
    }
  }

  detached() {
    console.log("EmpresaEdit detached");
  }


  getEmpresa(id: number) {
    this.api.getOne(Empresa, 'empresas', id)
      .then(item => {
        console.log("getEmpresa", item);
        if (item) {
          this.empresa = item;

          if (this.auth.authData.tabDocEmpresa) {
            this.getDocumentos();
          } else {
            this.selectTab("centros");
          }
        }
      });
  }

  // updateTable() {
  //   console.log("Empresas: updateTable");
  //   var tableID = "";
  //   if (this.tab == "empresa") {
  //     if (this.docWidget) {
  //       this.docWidget.updateTable();
  //     }
  //     return;
  //     // tableID = "documentos_empresa";
  //   } else if (this.tab == "centros") {
  //     tableID = "centros";
  //   } else if (this.tab == "trabajadores") {
  //     if (this.trabWidget) {
  //       this.trabWidget.updateTable();
  //     }
  //     return
  //   } else if (this.tab == "maquinas") {
  //     if (this.maqWidget) {
  //       this.maqWidget.updateTable();
  //     }
  //     return
  //   }

  //   // if (this.table) {
  //   //   this.table.destroy();
  //   // }

  //   this.api.loadDataTable(tableID)
  //     .then(table => {
  //       this.terminado = true;
  //       this.table = table;
  //       console.log("DOCUMENT TABLE: ", table);
  //     });
  // }

  updateDocumentos() {
    this.getDocumentos();
  }

  getDocumentos() {
    let params = {
      empresa: this.empresa.id,
    }
    this.api.getList(Documento, 'documentos', params)
      .then(items => {
        if (items) {
          this.documentos = items;
        }
        // this.updateTable();
      });
  }

  selectTab(tab: string) {
    this.tab = tab;

    if (this.tab == "empresa") {
      this.getDocumentos()
    } else if (this.tab == "trabajadores") {
      this.api.getTrabajadores(this.empresa);
    } else if (this.tab == "maquinas") {
      this.api.getMaquinas(this.empresa);
    } else if (this.tab == "centros") {
      this.api.getEmpresasCdt(this.empresa.id);
    }

  }

  selectTabCdt(tab: string) {
    this.tabCdt = tab;
  }

  ///////////////////////////////////
  //// Centros de trabajo
  ///////////////////////////////////

  // getCentros() {
  //   let params = {
  //     empresa: this.empresa.id,
  //   }
  //   this.api.getList(EmpresaCdT, 'empresa_centros', params)
  //     .then(items => {
  //       if (items) {
  //         this.centros = items;
  //       }
  //       this.updateTable();
  //     });
  // }

  editCentro(ct: EmpresaCdT, filterType?: number) {
    this.router.navigateToRoute('centroEdit', { empID: this.empresa.id, centroID: ct.id, filterType: filterType })
  }

  ///////////////////////////////////
  //// Trabajadores
  ///////////////////////////////////

  // getTrabajadores() {
  //   let params = {
  //     empresa: this.empresa.id,
  //   }
  //   this.api.getList(Trabajador, 'trabajadores', params)
  //     .then(items => {
  //       if (items) {
  //         this.trabajadores = items;
  //         this.trabajadores.forEach(t => {
  //           t.empresaCached = this.empresa;
  //         });
  //         this.empresa.trabajadores = this.trabajadores;
  //       }
  //     });
  // }
}
