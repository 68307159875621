import { autoinject, containerless, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { DialogController } from 'aurelia-dialog';
import { ActividadPreventiva } from 'entities/actPrev';
import { ActividadPreventivaEdit } from './edit';
import moment from 'moment';

@containerless()
@autoinject
export class CerrarForm {

  @observable actPrev: ActividadPreventiva;
  title: string;
  @observable errors: string;


  constructor(private api: Api, private ea: EventAggregator, private controller: DialogController) {
  }

  activate(model) {
    this.title = this.api.tr("cerrar_actprev");
    this.actPrev = model.actPrev;
  }

  attached() {
  }

  save() {

    if (this.actPrev.fecha_fin == "") {
      this.errors = this.api.tr("error_fecha_fin");
      return;
    }


    let fIni = moment(this.actPrev.fecha_inicio + " " + this.actPrev.hora_inicio, "DD-MM-YYYY HH:mm:ss");
    let fFin = moment(this.actPrev.fecha_fin + " " + this.actPrev.hora_fin, "DD-MM-YYYY HH:mm:ss");
    if (fIni.isValid() && fFin.isValid() && fFin < fIni) {
      this.api.alert(this.api.tr("error_detectado"), this.api.tr("error_act_cerrar"));
      return;
    }

    this.controller.close(true, "");
  }

  cancel() {
    this.controller.cancel(false);
  }
}
