import { autoinject, PLATFORM } from 'aurelia-framework';
import { RouterConfiguration, Router } from 'aurelia-router';

@autoinject
export class Scheduler {
  public router: Router;

  configureRouter(config: RouterConfiguration, router: Router) {
    config.map([
      // Devices
      { route: '', redirect: 'list' },
      { route: 'list', name: 'list', moduleId: PLATFORM.moduleName('./list'), title: "Empresas" },
      { route: 'edit/:empID', name: 'empresaEdit', moduleId: PLATFORM.moduleName('./edit'), title: "Editar empresa" },
      { route: 'validate-doc', name: 'validateDoc', moduleId: PLATFORM.moduleName('./validate-doc'), title: "Validar documentación" },
      { route: 'comunicaciones', name: 'comunicaciones', moduleId: PLATFORM.moduleName('./comunicaciones'), title: "Comunicaciones" },
      // { route: 'edit/:empID/trabajadores', name: 'trabajadores', moduleId: PLATFORM.moduleName('./../trabajadores/index'), nav: false, title: 'Trabajadores' },
      { route: 'edit/:empID/trabajadores/:trabID', name: 'trabajadorEdit', moduleId: PLATFORM.moduleName('./../trabajadores/edit'), title: "Editar trabajador" },
      { route: 'edit/:empID/maquinas/:maqID', name: 'maquinaEdit', moduleId: PLATFORM.moduleName('./../maquinas/edit'), title: "Editar maquina" },
      { route: 'edit/:empID/centros/:centroID', name: 'centroEdit', moduleId: PLATFORM.moduleName('./../centros/edit'), title: "Editar centro de trabajo" },
      { route: 'edit/:empID/emp-cdt/:empCdt/trab-cdt/:trabCdtID', name: 'trabajadorCdtEdit', moduleId: PLATFORM.moduleName('./../trabajadoresCdt/edit'), title: "Editar trabajador en Cdt" },
      { route: 'edit/:empID/emp-cdt/:empCdt/act-prev/:actPrevID', name: 'actPrevEdit', moduleId: PLATFORM.moduleName('./../actividadPreventiva/edit'), title: "Editar actividad preventiva en Cdt" },
      { route: 'edit/:empID/emp-cdt/:empCdt/act-prev/:actPrevID/informe-pee', name: 'informePEE', moduleId: PLATFORM.moduleName('./../actividadPreventiva/informe-pee'), title: "Informe de Plan de Emergencia y Evacuación" },
      { route: 'edit/:empID/emp-cdt/:empCdt/act-prev/:actPrevID/informe-pee-v2', name: 'informePEEv2', moduleId: PLATFORM.moduleName('./../actividadPreventiva/informe-pee2'), title: "Informe de Plan de Emergencia y Evacuación v2" },
      { route: 'edit/:empID/emp-cdt/:empCdt/act-prev/:actPrevID/informe-simulacro', name: 'informeSimulacro', moduleId: PLATFORM.moduleName('./../actividadPreventiva/informe-simulacro'), title: "Informe de Simulacro" },
      { route: 'edit/:empID/emp-cdt/:empCdt/act-prev/:actPrevID/informe-visita', name: 'informeVisita', moduleId: PLATFORM.moduleName('./../actividadPreventiva/informe-visita'), title: "Informe de Visita" },
      { route: 'edit/:empID/emp-cdt/:empCdt/accidente/:accidenteID', name: 'accidenteEdit', moduleId: PLATFORM.moduleName('./../accidente/edit'), title: "Editar accidente en Cdt" },
      { route: 'edit/:empID/emp-cdt/:empCdt/accidente/:accidenteID/informe', name: 'accidenteInforme', moduleId: PLATFORM.moduleName('./../accidente/accidente-inf'), title: "Informe de accidente/incidente" },
      { route: 'edit/:empID/emp-cdt/:empCdt/accidente/:accidenteID/informev2', name: 'accidenteInformeV2', moduleId: PLATFORM.moduleName('./../accidente/accidente-inf-v2'), title: "Informe de accidente/incidente" },
      { route: 'edit/:empID/emp-cdt/:empCdt/accidente/:accidenteID/trab/:accidenteTrabID', name: 'accidenteTrabEdit', moduleId: PLATFORM.moduleName('./../accidente-trab/edit'), title: "Editar trabajador accidentado en Cdt" }

    ]);

    this.router = router;
  }
}
