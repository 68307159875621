import { Router } from 'aurelia-router';
import { ActividadPreventiva, MedidaCorrectora, TrabajadorActividadPreventiva } from './../../entities/actPrev';
import { autoinject, observable } from 'aurelia-framework';
import { Api } from 'utils/api';
import moment from 'moment';

@autoinject
export class InformePee {

  @observable title: string
  actPrev: ActividadPreventiva;

  trabajadoresPorCat: Map<string, TrabajadorActividadPreventiva[]> = new Map();

  constructor(private api: Api, public router: Router) {
  }

  activate(params) {
    this.title = "Pla d'Emergència";

    this.api.getActividadPreventivaCompleta(params).then((actPrev) => {
      this.actPrev = actPrev;
      document.title = this.api.getTituloInformeActPrev(this.actPrev, "Pla d'Emergència");


      // Ordenar situaciones de riesgo por grupo
      this.actPrev.trabajadores.forEach((trab) => {

        let list: TrabajadorActividadPreventiva[];
        if (this.trabajadoresPorCat.has(trab.pee_categoria)) {
          list = this.trabajadoresPorCat.get(trab.pee_categoria);
        } else {
          list = [];
        }
        list.push(trab);
        this.trabajadoresPorCat.set(trab.pee_categoria, list);
      });
    });
  }

  imprimir() {
    window.print();
  }

  goBack() {
    this.router.navigateBack();
  }
}
