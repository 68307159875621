import { autoinject, computedFrom, observable, bindable } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import * as _ from 'lodash';
import { EyBasePage } from 'components/ey-base-page';
import { Api } from 'utils/api';
import { Documento } from 'entities/documento';
import { EyDocumentos, FilterType } from 'components/ey-documentos';
import AuthService from 'authService';
import moment from 'moment';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

@autoinject()
export class ValidateDoc extends EyBasePage {

  @observable documentos: Documento[] = [];
  @observable documentos_sin_subir: Documento[] = [];
  @observable documentos_por_caducar: Documento[] = [];
  // @observable documentos_caducados: Documento[] = [];
  @observable fechaDesde: moment.Moment;
  @observable fechaHasta: moment.Moment;
  // docWidget: EyDocumentos;

  tipo: string;

  fechaSub: Subscription;

  constructor(private api: Api, public router: Router, private ea: EventAggregator,
    private auth: AuthService) {
    super(router);

    this.fechaHasta = moment();
    this.fechaDesde = this.api.getStartTime(this.fechaHasta);
  }

  activate(params) {
    this.tipo = params.tipo;
    this.getDocumentos();
  }

  deactivate() {
  }

  attached() {
    // this.docWidget.parent = this;

    this.fechaSub = this.ea.subscribe('filter_fechas', (data) => {
      this.fechaDesde = data.f_desde;
      this.fechaHasta = data.f_hasta;
      console.log("filter_fechas", this.fechaDesde, this.fechaHasta);
      this.getDocumentos();
    });
  }

  detached() {
    if (this.fechaSub) {
      this.fechaSub.dispose();
    }
  }

  getDocumentos() {
    let params = {
      f_desde: this.api.getFechaFormatted(this.fechaDesde),
      f_hasta: this.api.getFechaFormatted(this.fechaHasta),
      tipo: this.tipo
    }

    this.documentos = [];
    this.documentos_sin_subir = [];
    this.documentos_por_caducar = [];
    // this.documentos_caducados = [];
    this.api.getList(Documento, "documentos_por_validar", params)
      .then((items: Documento[]) => {
        if (items) {
          if (this.tipo == "validar" || this.tipo == "subida") {
            this.documentos = items;
          } else {
            for (let d of items) {
              if (!d.subido) {
                this.documentos_sin_subir.push(d);
              } else {
                if (d.vigente) {
                  this.documentos_por_caducar.push(d);
                }
                // else {
                //   this.documentos_caducados.push(d);
                // }
              }
            }
          }
        }
        // console.log("getDocumentos", items);
      });
  }

  // updateDocumentos() {
  //   this.getDocumentos();
  // }

}
