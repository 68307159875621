import { EyTrabajadoresCdt } from './../../components/ey-trabajadoresCdt';
import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { Empresa } from 'entities/empresa';
import * as _ from 'lodash';
import { EyBasePage } from 'components/ey-base-page';
import { Api } from 'utils/api';
import { Documento } from 'entities/documento';
import { Trabajador } from 'entities/trabajador';
import { EyDocumentos, FilterType } from 'components/ey-documentos';
import { EmpresaCdT } from 'entities/centro';
import { TrabajadorCdt } from 'entities/trabajadorCdt';
import AuthService from 'authService';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

@autoinject()
export class CentroEdit extends EyBasePage {
  empresaCdt: EmpresaCdT;
  // empresa: Empresa;
  @observable documentos: Documento[] = [];
  // @observable trabajadoresCdt: TrabajadorCdt[] = [];

  @observable docWidget: EyDocumentos;
  trabWidget: EyTrabajadoresCdt;
  filterType: FilterType = FilterType.POR_SUBIR;

  tab: string = "documentos";

  trabSub: Subscription;
  docSub: Subscription;

  constructor(private api: Api, public router: Router, private ea: EventAggregator, private auth: AuthService) {
    super(router);
  }

  activate(params) {

    console.log("CentroEdit", params)
    this.getCentro(params.empID, params.centroID)
      .then(() => {
        if (params.filterType) {
          this.filterType = Number(params.filterType);
        }

        this.trabSub = this.ea.subscribe("trabajador_cdt::edit", (data) => {
          this.router.navigateToRoute('trabajadorCdtEdit',
            {
              empID: this.empresaCdt.empresaCached.id,
              empCdt: data.empCdt,
              trabCdtID: data.trabCdtID
            });
        });

        this.docSub = this.ea.subscribe("select::doc", (data) => {
          console.log("select::doc", data);

          if (data.owner instanceof TrabajadorCdt) {
            this.router.navigateToRoute('trabajadorCdtEdit',
              {
                empID: this.empresaCdt.empresaCached.id,
                empCdt: data.owner.empresa_cdt_id,
                trabCdtID: data.owner.id,
                filterType: data.filterType
              });
          }
        });

        if (params.tab) {
          this.selectTab(params.tab);
        } else if (this.auth.authData.tab_cdt_emp_doc) {
          this.selectTab('documentos');
        } else if (this.auth.authData.tab_cdt_trab) {
          this.selectTab('trabajadores');
        } else if (this.auth.authData.tabCdtDocActivo && this.empresaCdt.ct.vis_doc) {
          this.selectTab('documentos_centro');
        } else if (this.auth.authData.cdt_gestion) {
          this.selectTab('cdt_gestion');
        }
      });
  }

  deactivate() {
    if (this.trabSub) {
      this.trabSub.dispose();
    }
    if (this.docSub) {
      this.docSub.dispose();
    }
  }

  attached() {
    // this.docWidget.parent = this;
    // this.docWidget.setFilter(this.filterType);
  }

  detached() {
  }

  docWidgetChanged() {
    if (!this.docWidget) {
      return;
    }

    this.docWidget.parent = this;
    this.docWidget.setFilter(this.filterType);
  }

  getCentro(empID: number, centroID: number): Promise<void> {

    if (!empID || !centroID) {
      console.log("getCentro", empID, centroID);
      // this.router.navigate('#/');
      return;
    }

    let params = {
      empresa: empID,
    }
    return this.api.getOne(EmpresaCdT, 'empresa_centros', centroID, params)
      .then(item => {
        console.log("getCentros", item);
        if (item) {
          this.empresaCdt = item;
          if (!this.empresaCdt.empresaCached) {
            this.api.getEmpresas()
              .then(() => {
                this.empresaCdt.empresaCached = this.api.empresaByID(this.empresaCdt.empresa_id);
              })
          }

          // this.getDocumentos();
        }
      });
  }

  // updateTable() {
  //   console.log("Empresas: updateTable");
  //   if ((this.tab == "documentos" || this.tab == "documentos_centro") && this.docWidget) {
  //     this.docWidget.updateTable();
  //   } else if (this.tab == "trabajadores" && this.trabWidget) {
  //     this.trabWidget.updateTable();
  //   }
  // }

  updateDocumentos() {
    this.getDocumentos();
  }

  getDocumentos() {

    let params = {};
    if (this.tab == "documentos_centro") {
      params = {
        centro: this.empresaCdt.ct.id
      }
    } else if (this.tab == "documentos") {
      params = {
        empresa: this.empresaCdt.empresa_id,
        empresa_cdt: this.empresaCdt.id,
      }
    }

    if (Object.keys(params).length === 0) {
      return;
    }

    this.api.getList(Documento, 'documentos', params)
      .then(items => {
        if (items) {
          this.documentos = items;
        }
        // this.updateTable();
      });
  }

  selectTab(tab: string) {
    this.tab = tab;

    if (this.tab == "documentos" || this.tab == "documentos_centro") {
      this.getDocumentos()
    } else if (this.tab == "trabajadores") {
      this.api.getTrabajadoresCdt(this.empresaCdt);
    } else if (this.tab == "cdt_gestion") {

    }
  }
}
