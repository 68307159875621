
import { autoinject, containerless, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { DialogController } from 'aurelia-dialog';
import { Email } from 'entities/usuario';

@containerless()
@autoinject
export class EmailForm {

  @observable email: Email;
  title: string;


  constructor(private api: Api, private ea: EventAggregator, private controller: DialogController) {
  }

  activate(model) {
    this.email = model.email;
    this.title = this.api.tr("comunicaciones");
  }

  attached() {
  }

}
