import { autoinject, observable, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'utils/api';
import { Empresa } from 'entities/empresa';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import AuthService from 'authService';

@autoinject
export class EmpresaList {

  tab: string = "alta"
  table: any;
  docSub: Subscription;
  @bindable validadoSeleccion: string = "0";
  @observable terminado: boolean = false;

  constructor(public api: Api, private router: Router, private ea: EventAggregator, private auth: AuthService) {
  }

  activate() {
    this.api.getEmpresas()
      .then(() => {
        this.terminado = true;
      });
  }

  attached() {
    this.docSub = this.ea.subscribe("select::doc", (data) => {
      console.log("select::doc", data);
      if (data.owner instanceof Empresa) {
        this.editEmpresa(data.owner, data.filterType);
      }
    }); //{ parent: parent, type: type });
  }

  detached() {
    this.docSub.dispose();
  }

  editEmpresa(empresa: Empresa, filterType?: number) {
    this.router.navigateToRoute('empresaEdit', { empID: empresa.id, filterType: filterType })
  }

  selectTab(tab: string) {
    this.tab = tab;
  }

  validateDoc() {
    let params = { tipo: "validar" }
    this.router.navigateToRoute('validateDoc', params);
  }

  porRevisarDoc() {
    let params = { tipo: "revisar" }
    this.router.navigateToRoute('validateDoc', params);
  }

  docSubida() {
    let params = { tipo: "subida" }
    this.router.navigateToRoute('validateDoc', params);
  }

  comunicaciones() {
    this.router.navigateToRoute('comunicaciones');
  }
  // updateTable() {
  //   if (this.table) {
  //     this.table.destroy();
  //   }

  //   this.api.loadDataTable("empresas")
  //     .then(table => {
  //       this.table = table;
  //     });
  // }
}
