import { Email } from './../../entities/usuario';
import { autoinject, computedFrom, observable, bindable, PLATFORM } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import * as _ from 'lodash';
import { EyBasePage } from 'components/ey-base-page';
import { Api } from 'utils/api';
import { Documento } from 'entities/documento';
import { EyDocumentos, FilterType } from 'components/ey-documentos';
import AuthService from 'authService';
import moment from 'moment';
import { EmailForm } from './email-form';
PLATFORM.moduleName('./email-form');

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

@autoinject()
export class Comunicaciones extends EyBasePage {

  @observable fechaDesde: moment.Moment;
  @observable fechaHasta: moment.Moment;

  @observable emails: Email[] = [];

  fechaSub: Subscription;

  constructor(private api: Api, public router: Router, private ea: EventAggregator,
    private auth: AuthService) {
    super(router);

    this.fechaHasta = moment();
    this.fechaDesde = this.api.getStartTime(this.fechaHasta);
  }

  activate(params) {
    this.getEmails();
  }

  deactivate() {
  }

  attached() {
    this.fechaSub = this.ea.subscribe('filter_fechas', (data) => {
      this.fechaDesde = data.f_desde;
      this.fechaHasta = data.f_hasta;
      console.log("filter_fechas", this.fechaDesde, this.fechaHasta);
      this.getEmails();
    });
  }

  detached() {
    if (this.fechaSub) {
      this.fechaSub.dispose();
    }
  }

  getEmails() {
    let params = {
      f_desde: this.api.getFechaFormatted(this.fechaDesde),
      f_hasta: this.api.getFechaFormatted(this.fechaHasta),
    }

    this.emails = [];

    this.api.getList(Email, "emails", params)
      .then((items: Email[]) => {
        this.emails = items;
      });
  }

  verEmail(e: Email) {
    this.api.showEditorDialog({ viewModel: EmailForm, model: { email: e } });
  }
}
