import { autoinject, computedFrom, observable, PLATFORM } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import * as _ from 'lodash';
import { EyBasePage } from 'components/ey-base-page';
import { Api } from 'utils/api';
import { Documento } from 'entities/documento';
import { EyDocumentos } from 'components/ey-documentos';
import { EmpresaCdT } from 'entities/centro';
import AuthService from 'authService';
import { ActividadPreventiva, MedidaCorrectora } from 'entities/actPrev';
import { EyActPrevTrabs } from 'components/ey-act-prev-trabs';
import moment from 'moment';
import { EyActPrevForm } from 'components/ey-act-prev-form';
import { Trabajador } from 'entities/trabajador';
import { Empresa } from 'entities/empresa';
import { CerrarForm } from './cerrar-form';
PLATFORM.moduleName('./cerrar-form');

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

@autoinject()
export class ActividadPreventivaEdit extends EyBasePage {

  actPrevID: number;
  empID: number;
  empCdT: number;

  @observable actividadPreventiva: ActividadPreventiva;
  @observable empresaCdt: EmpresaCdT;
  @observable documentos: Documento[] = [];
  docWidget: EyDocumentos;
  trabWidget: EyActPrevTrabs;

  tab: string = "";

  ready: boolean = false;
  saveChanges: boolean = false;
  lastChange: moment.Moment;

  // settedSub: Subscription;
  savedSub: Subscription;
  // trabSub: Subscription;
  // docSub: Subscription;


  @observable trabList: Trabajador[] = [];
  @observable empList: Empresa[] = [];

  self: ActividadPreventivaEdit;

  constructor(private api: Api, public router: Router, private ea: EventAggregator, private auth: AuthService) {
    super(router);
    this.self = this;
  }

  activate(params) {
    console.log("ActPrevEdit", params)

    this.actPrevID = params.actPrevID;
    this.empID = params.empID;
    this.empCdT = params.empCdt;
    this.getCentro(this.empID, this.empCdT).then(() => {
      this.getActividadPreventiva();
    });
  }

  deactivate() {
    // this.trabSub.dispose();
    // this.docSub.dispose();
  }

  canDeactivate() {
    if (this.ready && this.saveChanges) {
      return confirm(this.api.tr("cambios_sin_guardar"));
    }
    return true;
  }

  attached() {
    if (this.docWidget) {
      this.docWidget.parent = this;
    }

    this.savedSub = this.eventAggregator.subscribe('actprev:saved', () => {
      this.resetSaveFlags();
    });

    // Forzar esperar un poco antes de cambiar cosas
    setTimeout(() => {
      this.ready = true;
      this.saveChanges = false
    }, 2 * 1000);
  }

  detached() {
    this.savedSub.dispose();
    // this.settedSub.dispose();
  }

  getCentro(empID: number, empCdt: number): Promise<any> {

    if (!empID || !empCdt) {
      this.router.navigate('#/');
      return;
    }

    let params = {
      empresa: empID,
    }
    return this.api.getOne(EmpresaCdT, 'empresa_centros', empCdt, params)
      .then(item => {
        console.log("getCentros", item);
        if (item) {
          this.empresaCdt = item;
          if (!this.empresaCdt.empresaCached) {
            this.api.getEmpresas()
              .then(() => {
                this.empresaCdt.empresaCached = this.api.empresaByID(this.empresaCdt.empresa_id);
              })
          }
        }
      });
  }

  getActividadPreventiva() {

    let params = {
      empresa: this.empresaCdt.empresa_id,
      empresa_cdt: this.empresaCdt.id,
    }

    this.api.getOne(ActividadPreventiva, 'actividades_preventivas', this.actPrevID, params)
      .then(item => {
        this.actividadPreventiva = item;
        this.actividadPreventiva.empCT = this.empresaCdt;

        this.selectCorrectTab();


        this.api.getTrabajadoresActPrev(this.empresaCdt, this.actividadPreventiva);
      });
  }

  selectCorrectTab() {
    if (this.editable && this.actividadPreventiva.tieneTab) {
      this.selectTab('especifico');
    } else if (this.editable) {
      this.selectTab('trabajadores');
    } else {
      this.selectTab('documentos')
    }
  }

  editarActPrev() {
    if (!this.empresaCdt) {
      return;
    }

    this.api.showEditorDialog({ viewModel: EyActPrevForm, model: { parent: this, actprev: this.actividadPreventiva } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          console.log("newActPrev", response)
          this.lastChange = moment();
        }
      });
  }

  guardarActPrev(): boolean {

    if (this.actividadPreventiva.tipo_id == 'F') {
      // verificación plan de emergencia

      if (this.actividadPreventiva.pe_sim_fechas) {
        let fSimIni = moment(this.actividadPreventiva.pe_sim_fecha_inicio + " " + this.actividadPreventiva.pe_sim_hora_inicio, "DD-MM-YYYY HH:mm:ss");
        let fSimFin = moment(this.actividadPreventiva.pe_sim_fecha_fin + " " + this.actividadPreventiva.pe_sim_hora_fin, "DD-MM-YYYY HH:mm:ss");
        if (!fSimIni.isValid() || !fSimFin.isValid() || fSimFin < fSimIni) {
          this.api.alert(this.api.tr("error_detectado"), this.api.tr("error_simulacro_fechas"));
          return false;
        }
      }

      if (this.actividadPreventiva.pe_jefe != 0 &&
        (this.actividadPreventiva.pe_jefe == this.actividadPreventiva.pe_jefe_sup1 ||
          this.actividadPreventiva.pe_jefe == this.actividadPreventiva.pe_jefe_sup2
          || this.actividadPreventiva.pe_jefe == this.actividadPreventiva.pe_jefe_sup3)) {
        this.api.alert(this.api.tr("error_detectado"), this.api.tr("error_des_1"));
        return false;
      }

      if (this.actividadPreventiva.pe_jefe_sup1 != 0 &&
        (this.actividadPreventiva.pe_jefe_sup1 == this.actividadPreventiva.pe_jefe_sup2
          || this.actividadPreventiva.pe_jefe_sup1 == this.actividadPreventiva.pe_jefe_sup3)) {
        this.api.alert(this.api.tr("error_detectado"), this.api.tr("error_des_2"));
        return false;
      }

      if (this.actividadPreventiva.pe_jefe_sup2 != 0 && this.actividadPreventiva.pe_jefe_sup2 == this.actividadPreventiva.pe_jefe_sup3) {
        this.api.alert(this.api.tr("error_detectado"), this.api.tr("error_des_3"));
        return false;
      }
    }

    if (this.actividadPreventiva.insp_posible_sancion < 0 ||
      this.actividadPreventiva.insp_sancion < 0) {
      this.api.alert(this.api.tr("error_detectado"), this.api.tr("error_imp_neg1"));
      return false;
    }

    if (this.actividadPreventiva.lib_tomo < 0) {
      this.api.alert(this.api.tr("error_detectado"), this.api.tr("error_imp_neg2"));
      return false;
    }

    if (this.actividadPreventiva.lib_pag_desde < 0) {
      this.api.alert(this.api.tr("error_detectado"), this.api.tr("error_imp_neg3"));
      return false;
    }

    if (this.actividadPreventiva.lib_pag_hasta < 0) {
      this.api.alert(this.api.tr("error_detectado"), this.api.tr("error_imp_neg4"));
      return false;
    }

    if (this.actividadPreventiva.lib_pag_desde > this.actividadPreventiva.lib_pag_hasta) {
      this.api.alert(this.api.tr("error_detectado"), this.api.tr("error_imp_neg5"));
      return false;
    }


    this.api.saveOne(ActividadPreventiva, this.actividadPreventiva, 'actividades_preventivas', this.actividadPreventiva.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }
        this.resetSaveFlags();
        this.selectCorrectTab();
      })
      .catch(error => {
        this.api.alert(this.api.tr("error_actualizacion"), error);
      });

    return true;
  }

  resetSaveFlags() {
    this.lastChange = moment();
    this.saveChanges = false;
  }
  // informeSimulacro() {
  //   // actualizar trabajadores
  //   this.api.getTrabajadoresActPrev(this.empresaCdt, this.actividadPreventiva);

  //   // this.api.getList(MedidasCorrectoras, 'medidas_correctoras', params)
  //   //   .then((items) => {
  //   //     if (this.actividadPreventiva) {
  //   //       this.actividadPreventiva.situacionesRiesgo = items;
  //   //     }
  //   //   });

  //   this.api.showEditorDialog({ viewModel: SimulacroInf, model: { actPrev: this.actividadPreventiva } });
  //   // this.dialogService.open({ viewModel: SimulacroInf, model: { actPrev: this.actividadPreventiva } })
  //   //   .whenClosed(response => {
  //   //     if (!response.wasCancelled) {
  //   //       // TODO: 
  //   //     };
  //   //   });
  // }

  informeSimulacro() {

    let fSimIni = moment(this.actividadPreventiva.pe_sim_fecha_inicio + " " + this.actividadPreventiva.pe_sim_hora_inicio, "DD-MM-YYYY HH:mm:ss");
    let fSimFin = moment(this.actividadPreventiva.pe_sim_fecha_fin + " " + this.actividadPreventiva.pe_sim_hora_fin, "DD-MM-YYYY HH:mm:ss");
    if (!fSimIni.isValid() || !fSimFin.isValid() || fSimFin < fSimIni) {
      this.api.alert(this.api.tr("error_detectado"), this.api.tr("error_simulacro_fechas"));
      return;
    }

    let params = {
      empID: this.empID,
      empCdt: this.empCdT,
      actPrevID: this.actPrevID
    };
    this.router.navigateToRoute('informeSimulacro', params);
  }


  informePEE() {
    let params = {
      empID: this.empID,
      empCdt: this.empCdT,
      actPrevID: this.actPrevID
    };
    this.router.navigateToRoute('informePEE', params);
  }

  informePEEv2() {
    let params = {
      empID: this.empID,
      empCdt: this.empCdT,
      actPrevID: this.actPrevID
    };
    this.router.navigateToRoute('informePEEv2', params);
  }

  informeVisita() {
    let params = {
      empID: this.empID,
      empCdt: this.empCdT,
      actPrevID: this.actPrevID
    };
    this.router.navigateToRoute('informeVisita', params);
  }

  updateDocumentos() {
    this.getDocumentos();
  }

  getDocumentos(): Promise<any> {
    let params = { actividad: this.actividadPreventiva.id };
    return this.api.getList(Documento, 'documentos', params)
      .then(items => {
        if (items) {
          this.documentos = items;
          this.actividadPreventiva.documentos = items;
        }
      });
  }

  selectTab(tab: string) {
    this.tab = tab;

    if (this.tab == "documentos") {
      this.getDocumentos()
    } else if (this.tab == "especifico") {
    } else if (this.tab == "trabajadores") {
      this.api.getTrabajadoresActPrev(this.empresaCdt, this.actividadPreventiva);
    }
  }

  changed() {
    if (!this.ready) {
      return;
    }
    this.saveChanges = true;
  }


  getTrabajadores() {
    if (this.empresaCdt && this.empresaCdt.trabajadoresCdt && this.empresaCdt.trabajadoresCdt.length > 0) {
      this.actualizarTrabajadores();
    } else {
      this.api.getTrabajadoresCdt(this.empresaCdt)
        .then(() => this.actualizarTrabajadores());
    }
  }

  actualizarTrabajadores() {
    this.trabList = [];
    if (this.empresaCdt && this.empresaCdt.trabajadoresCdt) {
      // Carga los trabajadores de la empresa que NO estén presentes en esta Actividad Preventiva

      for (let tCdt of this.empresaCdt.trabajadoresCdt) {
        if (!tCdt.presente) {
          continue;
        }

        this.trabList.push(tCdt.trabajador);
      }
    }

    this.trabList.sort((a, b) => {
      if (a.nombreCompleto < b.nombreCompleto) { return -1; }
      if (a.nombreCompleto > b.nombreCompleto) { return 1; }
    });

    let ninguno = { id: 0, nombreCompletoCif: this.api.tr("ninguno") } as Trabajador
    this.trabList = [ninguno].concat(this.trabList);
  }

  getEmpresas() {
    if (this.api.empresas.length == 0) {
      this.api.getEmpresas()
        .then(() => this.actualizarEmpresas());
    } else {
      this.actualizarEmpresas();
    }
  }

  actualizarEmpresas() {
    console.log("actualizarTrabajadores", this.empresaCdt.trabajadoresCdt)
    this.empList = [];
    this.empList.push({ id: 0, nombreCompletoCif: this.api.tr("ninguno") } as Empresa);

    for (let emp of this.api.empresas) {
      this.empList.push(emp);
    }

    this.empList.sort((a, b) => {
      if (a.nombre < b.nombre) { return -1; }
      if (a.nombre > b.nombre) { return 1; }
    });
  }

  cerrar() {

    if (!this.guardarActPrev()) {
      return;
    }

    let minTrabs = 2;
    if (this.actividadPreventiva.peSimFechasBool) {
      minTrabs = this.actividadPreventiva.min_trabs_sim;
    } else if (this.actividadPreventiva.min_trabs > 0) {
      minTrabs = this.actividadPreventiva.min_trabs;
    }

    if (this.actividadPreventiva.tipo_id == 'F') {
      if (this.actividadPreventiva.pe_actividad_id <= 0 ||
        this.actividadPreventiva.pe_megafonia_id <= 0 ||
        this.actividadPreventiva.pe_lugar == "" ||
        this.actividadPreventiva.pe_fecha == "" ||
        this.actividadPreventiva.pe_jefe <= 0 ||
        this.actividadPreventiva.pe_jefe_sup1 <= 0 ||
        this.actividadPreventiva.pe_jefe_sup2 <= 0 ||
        this.actividadPreventiva.trabajadores.length < minTrabs) {

        this.api.alert(this.api.tr("error_faltan_datos"), this.api.tr("error_faltan_datos_desc") + " " + minTrabs);
        return;
      }
    }

    // if (this.actividadPreventiva.tipo_id == 'F') {
    //   let fSimIni = moment(this.actividadPreventiva.pe_sim_fecha_inicio + " " + this.actividadPreventiva.pe_sim_hora_inicio, "DD-MM-YYYY HH:mm:ss");
    //   let fSimFin = moment(this.actividadPreventiva.pe_sim_fecha_fin + " " + this.actividadPreventiva.pe_sim_hora_fin, "DD-MM-YYYY HH:mm:ss");
    //   if (!fSimIni.isValid() || !fSimFin.isValid() || fSimFin < fSimIni) {
    //     this.api.confirm(this.api.tr("error_detectado"), this.api.tr("error_simulacro_fechas"))
    //       .then(b => {
    //         if (!b)
    //           return;
    //       });
    //   }
    // }

    // Tenemos que pedir los documentos, por si cierran la act-prev
    this.getDocumentos()
      .then(() => {
        if (this.actividadPreventiva.documentos && this.actividadPreventiva.documentos.length > 0) {
          let sinSubir = false;
          for (let doc of this.actividadPreventiva.documentos) {
            if (!doc.subido) {
              sinSubir = true;
              break;
            }
          }
          if (sinSubir) {

            let err = "";
            if (!this.auth.authData.prl_ver_doc) {
              err = this.api.tr("error_act_docs_1");
            } else {
              err = this.api.tr("error_act_docs_2");
            }

            this.api.confirm(this.api.tr("error_detectado"), err)
              .then(b => {
                if (!b)
                  return;

                this.cerrarOK();
              });
          } else {
            this.cerrarOK();
          }
        } else {
          this.cerrarOK();
        }

      });
  }

  cerrarOK() {
    this.api.dialogService.open({ viewModel: CerrarForm, model: { actPrev: this.actividadPreventiva } })
      .whenClosed(response => {
        if (!response.wasCancelled) {
          this.actividadPreventiva.estado_id = "3";
          this.guardarActPrev();
        };
      });
  }

  get editable(): boolean {
    if (!this.actividadPreventiva) {
      return false;
    }

    return this.auth.authData.prl_edita_cerrada || this.actividadPreventiva.estado_id != '3'
  }
}
