import { Router } from 'aurelia-router';
import { ActividadPreventiva, MedidaCorrectora } from './../../entities/actPrev';
import { autoinject, observable } from 'aurelia-framework';
import { Api } from 'utils/api';

@autoinject
export class InformeSimulacro {

  @observable title: string
  actPrev: ActividadPreventiva;

  medCorrPorFamilia: Map<string, MedidaCorrectora[]> = new Map();

  constructor(private api: Api, public router: Router) {
  }

  activate(params) {
    this.title = this.api.tr("inf_simulacro");

    this.api.getActividadPreventivaCompleta(params).then((actPrev) => {
      this.actPrev = actPrev;
      document.title = this.api.getTituloInformeActPrev(this.actPrev, this.api.tr("simulacro"));

      // Ordenar situaciones de riesgo por grupo
      this.actPrev.situacionesRiesgo.forEach((mc) => {

        let list: MedidaCorrectora[];
        if (this.medCorrPorFamilia.has(mc.ig_grupo)) {
          list = this.medCorrPorFamilia.get(mc.ig_grupo);
        } else {
          list = [];
        }
        list.push(mc);
        this.medCorrPorFamilia.set(mc.ig_grupo, list);
      });
    });
  }

  imprimir() {
    window.print();
  }

  goBack() {
    this.router.navigateBack();
  }
}
